<template>
  <div>
    <v-container
      class="v-container-fluid pb-5"
      style="padding: 0px;"
      fluid
    >
      <v-row class="justify-content-center">
        <!-- <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
        >
          <div>
            <div class="display-flex">
              <div class="content-fixed-image">
                <div
                  v-for="(item, index) in 5"
                  :key="index"
                >
                  <div
                    @click="selectImage(item)"
                    class="content-image cursor-pointer"
                  >
                    <img
                      src="https://s3.amazonaws.com/thumbnails.venngage.com/template/fc8535df-be09-4c80-8ea5-a69a34b2318e.png"
                      alt=""
                    >
                  </div>
                </div>
              </div>
              <div class="content-resize-image">
                <zoom-image :imageSelected="imageSelected"></zoom-image>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
        >
          <div>
            <h2>contenido</h2>
          </div>
        </v-col> -->
        <v-col
          cols="12"
          lg="8"
          md="8"
          sm="12"
        >
          <!-- <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <div class="container-card">
                <p class="text-title-description mon-bold">{{ texts.vendorDetail.textDescription }}</p>
                <div style="height: 150px; overflow: auto;">
                  <p class="text-description mon-regular">
                    {{ arrVendorDetail.sDescription }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <div
                class="container-card"
                style="padding:0px;"
              >
                <!-- <div class="pa-4 display-flex align-items-center border-bottom">
                  <p class="description-title mon-medium">
                    <v-icon
                      color="#283C4D"
                      size="16px"
                    >
                      mdi-av-timer
                    </v-icon>
                    {{ texts.vendorDetail.infoVendor.responseTime }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="description-text mon-regular">
                    Text result from api
                  </p>
                </div> -->
                <div class="pa-4 display-flex align-items-center border-bottom">
                  <p class="description-title mon-medium">
                    <v-icon
                      color="#283C4D"
                      size="16px"
                    >
                      mdi-check-underline
                    </v-icon>
                    {{ texts.vendorDetail.infoVendor.orderComplete }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="description-text mon-regular">
                    {{ this.arrVendorDetail.dFinishedOrders }}
                  </p>
                </div>
                <div class="pa-4 display-flex align-items-center border-bottom">
                  <p class="description-title mon-medium">
                    <v-icon
                      color="#283C4D"
                      size="16px"
                    >
                      mdi-calendar-blank
                    </v-icon>
                    {{ texts.vendorDetail.infoVendor.registrationDate }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="description-text mon-regular">
                    {{ arrVendorDetail.tCreatedAtView }}
                  </p>
                </div>
                <div class="pa-4 display-flex align-items-center border-bottom">
                  <p class="description-title mon-medium">
                    <v-icon
                      color="#283C4D"
                      size="16px"
                    >
                      mdi-near-me
                    </v-icon>
                    {{ texts.vendorDetail.infoVendor.country }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="description-text mon-regular">
                    {{ arrVendorDetail.sCountryName }}
                  </p>
                </div>
                <div class="pa-4 display-flex align-items-center border-bottom">
                  <p class="description-title mon-medium">
                    <v-icon
                      color="#283C4D"
                      size="16px"
                    >
                      mdi-map-marker
                    </v-icon>
                    {{ texts.vendorDetail.infoVendor.state }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="description-text mon-regular">
                    {{ arrVendorDetail.sStateName }}
                  </p>
                </div>
                <div class="pa-4 display-flex align-items-center">
                  <p class="description-title mon-medium">
                    <v-icon
                      color="#283C4D"
                      size="16px"
                    >
                      mdi-map-marker
                    </v-icon>
                    {{ texts.vendorDetail.infoVendor.city }}
                  </p>
                  <v-spacer></v-spacer>
                  <p class="description-text mon-regular">
                    {{ arrVendorDetail.sLocationCity }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <div>
                <iframe
                  width="100%"
                  height="320"
                  src="https://www.youtube.com/embed/KPP4Cfupzhs?rel=0&showinfo=0&controls=1&autoplay=1&start=120&frameborder=0&allowfullscreen=0"
                >
                </iframe>
              </div>
            </v-col>
          </v-row> -->
        </v-col>
        <!-- <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <div class="container-card">
                <p class="text-title-description mon-bold">{{ texts.vendorDetail.textAttention }}</p>
                <div class="display-flex align-items-center pt-2 pb-2">
                  <p class="description-title mon-medium">Lunes</p>
                  <v-spacer
                    class="ml-2 mr-2"
                    style="border-bottom: 1px dashed #E0E0E0;"
                  ></v-spacer>
                  <p class="description-text mon-regular">7:00 am - 7:00pm</p>
                </div>
                <div class="display-flex align-items-center pt-2 pb-2">
                  <p class="description-title mon-medium">Martes</p>
                  <v-spacer
                    class="ml-2 mr-2"
                    style="border-bottom: 1px dashed #E0E0E0;"
                  ></v-spacer>
                  <p class="description-text mon-regular">7:00 am - 7:00pm</p>
                </div>
                <div class="display-flex align-items-center pt-2 pb-2">
                  <p class="description-title mon-medium">Miercoles</p>
                  <v-spacer
                    class="ml-2 mr-2"
                    style="border-bottom: 1px dashed #E0E0E0;"
                  ></v-spacer>
                  <p class="description-text mon-regular">7:00 am - 7:00pm</p>
                </div>
                <div class="display-flex align-items-center pt-2 pb-2">
                  <p class="description-title mon-medium">Jueves</p>
                  <v-spacer
                    class="ml-2 mr-2"
                    style="border-bottom: 1px dashed #E0E0E0;"
                  ></v-spacer>
                  <p class="description-text mon-regular">7:00 am - 7:00pm</p>
                </div>
                <div class="display-flex align-items-center pt-2 pb-2">
                  <p class="description-title mon-medium">Viernes</p>
                  <v-spacer
                    class="ml-2 mr-2"
                    style="border-bottom: 1px dashed #E0E0E0;"
                  ></v-spacer>
                  <p class="description-text mon-regular">7:00 am - 7:00pm</p>
                </div>
                <div class="display-flex align-items-center pt-2 pb-2">
                  <p class="description-title mon-medium">Sabado</p>
                  <v-spacer
                    class="ml-2 mr-2"
                    style="border-bottom: 1px dashed #E0E0E0;"
                  ></v-spacer>
                  <p class="description-text mon-regular">7:00 am - 7:00pm</p>
                </div>
                <div class="display-flex align-items-center pt-2 pb-2">
                  <p class="description-title mon-medium">Domingo</p>
                  <v-spacer
                    class="ml-2 mr-2"
                    style="border-bottom: 1px dashed #E0E0E0;"
                  ></v-spacer>
                  <p class="description-text mon-regular">7:00 am - 7:00pm</p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "VendorDetailContentTabOneLayout",
  props: {
    texts: {
      type: Object,
    },
    arrVendorDetail: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
    };
  },
  methods: {
    formatDate: function (date) {
      var new_date = MOMENT(date, "YYYY/MM/DD");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
  },
};
</script>

<style scoped>
.description-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0;
}
.description-text {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.text-title-description {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.text-description {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.container-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 15px 25px;
}

.text-title {
  text-align: left;
  font-size: 45px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.text-location {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
}

.text-name-warehouse {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.link-button-warehouse {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #0971fb;
  opacity: 1;
  text-transform: initial;
  padding: 0px !important;
}

.text-calification {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-reviews {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.border-top-line {
  border-top: 1px solid #c3c3c3;
}

.border-bottom-line {
  border-bottom: 1px solid #c3c3c3;
}

.text-min {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-max {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-price {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-number-price {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.button-add-cart {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  border-radius: 13px;
  height: 50px !important;
  font-size: 16px;
  text-transform: initial;
}

.input-amount {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  height: 50px;
  width: 60px;
  outline: none;
  text-align: center;
  padding: 5px;
}

.card-content-about {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}

.card-content-info {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.text-title-about-article {
  text-align: left;
  font-size: 22px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

.text-title-about-description-article {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

/* Chrome, Safari, Edge, Opera */
.input-amount::-webkit-outer-spin-button,
.input-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-amount[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.text-mouse-zoom {
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

/* images min */
.content-fixed-image {
  /* background-color: red; */
  width: 125px;
  padding: 10px;
}

.content-fixed-image .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

/* image zoom */

.content-resize-image {
  /* background-color: green; */
  width: calc(100% - 125px);
  padding: 10px;
}

.content-resize-image .content-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.content-resize-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-resize-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.width-50 {
  width: 50%;
}
</style>